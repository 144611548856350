import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../api/ApiSession";
import {MainContent, Wrapper} from "../common/component/widget";
import {pathSearch, TranslationPortalFile} from "../utils/constants";
import {SearchResults, TaxonomyFilterToolbar, TaxonomySettingToolbar} from "../component/snippet";
import {ContentBlock, GoToTop, LayoutRows, Loadable, MainContentPageHeader, TranslationLibFile} from "@sirdata/ui-lib";
import {TaxonomyFilters} from "../utils/taxonomy/TaxonomyFilters";
import {useLocation, useNavigate} from "react-router-dom";
import {TaxonomyPagedElements} from "../api/model/taxonomy/TaxonomyPagedElements";
import {TaxonomyPathItem} from "../api/model/taxonomy/TaxonomyNode";
import {MainHeader} from "../common/component/snippet";
import {handleCatchError} from "../common/utils/helper";

function Search() {
    const navigate = useNavigate();
    const location = useLocation();
    const {t: textTranslation} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [isLoadingResults, setLoadingResults] = useState(true);

    const [results, setResults] = useState<TaxonomyPagedElements>(new TaxonomyPagedElements());
    const [filters, setFilters] = useState<TaxonomyFilters>(new TaxonomyFilters());
    const [verticals, setVerticals] = useState<TaxonomyPathItem[]>([]);

    useEffect(() => {
        (async function () {
            try {
                setLoadingResults(true);

                const verticals = await session.getVerticals();
                setVerticals(verticals);

                const filters = TaxonomyFilters.fromQueryString(location.search);
                filters.vertical = verticals.find((it) => it.link === filters.vertical)?.link;
                setFilters(filters);

                const searchResults = await session.restTaxonomy.search(filters.toTaxonomyQueryParams());
                setResults(searchResults);
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to get results:", e.message));
            } finally {
                setLoadingResults(false);
            }
        })();
    }, [location]);

    const handleChangeFilters = (filters: TaxonomyFilters, requireRefresh: boolean) => {
        if (requireRefresh) {
            navigate(`${pathSearch}?${filters.toQueryString()}`);
        } else {
            setFilters(filters);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={filters.query ? textTranslation("search.title_with_query", {query: filters.query}) : textTranslation("search.title")} icon={{name: "search"}}/>
                <LayoutRows>
                    <ContentBlock>
                        <TaxonomyFilterToolbar initialFilters={filters} onChange={handleChangeFilters}/>
                        <TaxonomySettingToolbar initialFilters={filters} onChange={handleChangeFilters} verticals={verticals} enableSort/>
                    </ContentBlock>
                    <Loadable loading={isLoadingResults}>
                        <div className="search">
                            <div className="search__title">
                                <span className="search__title__label" dangerouslySetInnerHTML={{__html: textCommon("messages.results_found", {count: results.total})}}/>
                            </div>
                            {!!results.elements.length &&
                                <div className="search__results">
                                    <div className="search__results__head">
                                        <div className="search__results__head__column" style={{width: "2%"}}></div>
                                        <div className="search__results__head__column" style={{width: "1%"}}></div>
                                        <div className="search__results__head__column" style={{width: "25%"}}>{textTaxonomy("info.vertical")}</div>
                                        <div className="search__results__head__column">{textTaxonomy("info.name")}</div>
                                        <div className="search__results__head__column search__results__head__column--justify-center" style={{width: "5%"}}>{textTaxonomy("info.activation")}</div>
                                        <div className="search__results__head__column search__results__head__column--justify-center" style={{width: "5%"}}>{textTaxonomy("info.volume")}</div>
                                        <div className="search__results__head__column search__results__head__column--justify-center" style={{width: "5%"}}>{textTaxonomy("info.cpm")}</div>
                                        <div className="search__results__head__column" style={{width: "7%"}}/>
                                    </div>
                                    <SearchResults results={results} filters={filters}/>
                                </div>
                            }
                        </div>
                    </Loadable>
                    <GoToTop/>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Search;
