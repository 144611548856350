import {useEffect, useState} from "react";
import {TranslationPortalFile} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {CustomCategory} from "../../api/model/category/custom/CustomCategory";
import {CountOver, KeywordStats} from "../../api/model/category/custom/KeywordStats";
import {CustomCategoryConfigStep} from "../../api/model/category/custom/CustomCategoryConfigStep";
import {DEFAULT_KEYWORD_THRESHOLD, THRESHOLD_ITEMS, ThresholdStat} from "../../api/model/category/custom/Threshold";
import {ConfigHeader, ProgressBar, RelevancySlider, TagCloudItem} from "../../component/snippet";
import {Box, BoxProps, CircleStep, ContentBlock, LayoutColumns, LayoutRows, Loadable, MainContentPageHeader} from "@sirdata/ui-lib";
import {Formatter} from "../../common/utils/Formatter";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {handleCatchError} from "../../common/utils/helper";

function ContextualCustomCategoryRelevancy() {
    const {t: textConfig} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const {id: customCategoryId} = useParams() as {id: string};
    const [isEdit, setEdit] = useState(true);
    const [isTouched, setTouched] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const [customCategory, setCustomCategory] = useState<CustomCategory>(new CustomCategory());

    const [initialThreshold, setInitialThreshold] = useState<number>();
    const [currentStats, setCurrentStats] = useState<ThresholdStat>(new ThresholdStat());
    const [statsPerThreshold, setStatsPerThreshold] = useState<Map<number, ThresholdStat>>(new Map<number, ThresholdStat>());

    const [keywordsStats, setKeywordsStats] = useState<KeywordStats[]>([]);

    useEffect(() => {
        (async function () {
            setLoading(true);
            try {
                const customCategory = await session.restCustomCategory.getCustomCategory(+customCategoryId);
                setCustomCategory(customCategory);
                setEdit(customCategory.active);

                if (customCategory.keywords.length) {
                    const threshold: number = (customCategory.keywords[0]?.threshold) || DEFAULT_KEYWORD_THRESHOLD;
                    setInitialThreshold(threshold);
                    const keywordsOverview = await session.restCustomCategory.getKeywordsOverview(customCategory.keywords);

                    const statsPerThreshold: Map<number, ThresholdStat> = new Map<number, ThresholdStat>();
                    THRESHOLD_ITEMS.forEach((threshold) => {
                        const item: ThresholdStat = {
                            value: threshold,
                            percentage: Math.trunc(keywordsOverview.count_over[threshold as keyof CountOver] * 100 / keywordsOverview.count) + "%",
                            urlsCount: Formatter.formatNumber(keywordsOverview.count_over[threshold as keyof CountOver])
                        };
                        statsPerThreshold.set(threshold, item);
                    });
                    setStatsPerThreshold(statsPerThreshold);

                    const keywordStats = await session.restCustomCategory.getKeywordsStats(customCategory.keywords);
                    setKeywordsStats(keywordStats);

                    const currentStats = statsPerThreshold.get(threshold);
                    if (currentStats) setCurrentStats(currentStats);
                }
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [customCategoryId]);

    const handleSave = async (finish?: boolean) => {
        try {
            const newCustomCategory = new CustomCategory();
            newCustomCategory.load(customCategory);
            newCustomCategory.keywords?.forEach((it) => it.threshold = currentStats.value);
            if (finish) {
                newCustomCategory.active = true;
                newCustomCategory.pending = false;
            }
            await session.restCustomCategory.updateCustomCategory(newCustomCategory);
            if (isEdit) setTouched(false);
        } catch (e) {
            handleCatchError(e, (e) => console.error("Failed to save category:", e.message));
        }
    };

    const onChangeThreshold = (threshold: number) => {
        const currentStats = statsPerThreshold.get(threshold);
        if (currentStats) setCurrentStats(currentStats);
        setTouched(threshold !== initialThreshold);
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isTouched}/>
            <ConfigHeader
                step={CustomCategoryConfigStep.Relevancy}
                edit={isEdit}
                touched={isTouched}
                onSave={(finish) => handleSave(finish)}
                previousPath={customCategory.getRoute(CustomCategoryConfigStep.Keywords)}
                nextPath={customCategory.getRoute()}
                last
            />
            <MainContent>
                {!isEdit && <ProgressBar step={3}/>}
                <MainContentPageHeader
                    title={isEdit ? textConfig("relevancy.title_edit") : textConfig("relevancy.title_create")}
                    description={textConfig("relevancy.description")}
                    icon={!isEdit ? <CircleStep step={3}/> : undefined}
                />
                <LayoutRows>
                    <LayoutColumns>
                        <div className="relevancy-target">
                            <div className="relevancy-target-main">
                                <div className="relevancy-target-main-title">{textConfig("relevancy.targeting_relevancy")}</div>
                                {(initialThreshold && !isLoading) && <RelevancySlider initialValue={initialThreshold} statsPerThreshold={statsPerThreshold} onChange={onChangeThreshold}/>}
                            </div>
                            <div className="relevancy-target-result">
                                <Loadable loading={isLoading} loaderOptions={{cssClass: "relevancy-target-result-loader"}}>
                                    <div className="relevancy-target-result-content">
                                        <div className="relevancy-target-result-bar">
                                            <span className="back"/>
                                            <span className="track" style={{width: currentStats.percentage}}/>
                                        </div>
                                        <div className="relevancy-target-result-value">{currentStats.urlsCount} {textConfig("relevancy.urls_found")}</div>
                                    </div>
                                </Loadable>
                            </div>
                        </div>
                        <ContentBlock header={{title: {label: textConfig("relevancy.semantic_graph").toUpperCase()}}}>
                            <Box cssClass={"relevancy-tag-cloud"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                <Loadable loading={isLoading}>
                                    {keywordsStats?.map((item) =>
                                        <TagCloudItem key={item.keyword} name={item.keyword} score={item.count_over[currentStats.value as keyof CountOver]}/>
                                    )}
                                </Loadable>
                            </Box>
                        </ContentBlock>
                    </LayoutColumns>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default ContextualCustomCategoryRelevancy;
