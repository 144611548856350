import React, {FunctionComponent, useEffect, useState} from "react";
import {Segment} from "../../../api/model/segment/Segment";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {Action, Button, ButtonSize, ButtonStyle, Icon, IconTooltip, Loadable, Tooltip, TranslationLibFile} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {TaxonomyElementType} from "../../../api/model/taxonomy/TaxonomyElementType";
import {ModalContactUiEvent} from "../../../common/component/modal/ModalContact";
import {Distribution} from "../../../api/model/distribution/Distribution";
import {handleCatchError} from "../../../common/utils/helper";

type SegmentPlatformsProps = {
    segment: Segment;
    platform: string;
    isButtonStyle?: boolean;
};

const SegmentPlatforms: FunctionComponent<SegmentPlatformsProps> = ({segment, platform, isButtonStyle}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [isLoading, setLoading] = useState(true);
    const [isActive, setActive] = useState<boolean>();
    const [distributionIds, setDistributionIds] = useState<Map<string, string>>(new Map<string, string>());
    const [distributions, setDistributions] = useState<Distribution[]>([]);
    const [currentDistributions, setCurrentDistributions] = useState<Distribution[]>([]);
    const [copiedDistribution, setCopiedDistribution] = useState<Distribution>();

    const handleCopyId = (distribution: Distribution, id: string) => {
        copy(id);
        setCopiedDistribution(distribution);
        setTimeout(() => setCopiedDistribution(undefined), 1000);
    };

    const handleActivateDistribution = (distribution: Distribution) => {
        UIEventManager.emit(ModalContactUiEvent, {
            topicValue: "DISTRIBUTION",
            topicLabel: t("contact.topic.DISTRIBUTION"),
            message: t("contact.message.DISTRIBUTION", {type: "segment", name: `${segment.name} (${segment.id})`, platform: distribution.label})
        });
        setActive(false);
    };

    useEffect(() => {
        if (!isActive || !!distributions.length) {
            return;
        }

        (async () => {
            try {
                const distributionIds: Map<string, string> = new Map<string, string>();

                const distributions = await session.getDistributionsByType(TaxonomyElementType.SEGMENT);
                distributions.forEach((distribution) => {
                    distributionIds.set(distribution.name, segment.getDistributionId(distribution));
                });

                const adsId = await session.getAdsId(segment.id);
                if (adsId) {
                    const adsDistribution = Distribution.SIRDATA_ADS;
                    if (!distributions.some((it) => it.name === adsDistribution.name)) {
                        distributions.unshift(adsDistribution);
                    }
                    distributionIds.set(adsDistribution.name, adsId.toString());
                }

                setDistributions(distributions);
                setDistributionIds(distributionIds);
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load distributions:", e.message));
            } finally {
                setLoading(false);
            }
        })();
    }, [isActive, segment, distributions.length]);

    useEffect(() => {
        if (platform) {
            const selectedDistribution = distributions.find((it) => it.name === platform);
            if (selectedDistribution) {
                setCurrentDistributions([selectedDistribution]);
                return;
            }
        }
        setCurrentDistributions(distributions);
    }, [distributions, platform]);

    return (
        <div className={clsx("activation-platforms", {"activation-platforms--active": isActive})} onClick={(e) => e.stopPropagation()} onMouseLeave={() => setActive(false)}>
            <Tooltip text={textTaxonomy("action.activate_segment")} onClick={() => setActive(true)} cssClass="activation-platforms__button">
                {isButtonStyle ?
                    <Button size={ButtonSize.SMALL} style={ButtonStyle.PRIMARY_MIDNIGHT} icon={{name: "ios_share"}}>
                        {textCommon(Action.ACTIVATE.labelKey)}
                    </Button> :
                    <Icon name="ios_share"/>
                }
            </Tooltip>
            <div className="activation-platforms__overlay" onClick={() => setActive(false)}/>
            <div className="activation-platforms__content">
                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                    {currentDistributions.map((item) => ({distribution: item, value: distributionIds.get(item.name)})).map(({distribution, value}) =>
                        <div key={distribution.name} className="platform-item">
                            <div className="platform-item__info">
                                <span className="platform-item__name">{distribution.label}</span>
                                {value && <span className="platform-item__value">{value}</span>}
                            </div>
                            <span className="platform-item__icon">
                                {value ?
                                    <IconTooltip
                                        icon={copiedDistribution?.name === distribution.name ? Action.COPIED.icon : Action.COPY.icon}
                                        text={textTaxonomy("action.copy_id")}
                                        onClick={() => handleCopyId(distribution, value)}
                                    /> :
                                    <IconTooltip
                                        icon={{name: "live_help"}}
                                        text={textTaxonomy("action.request_distribution")}
                                        onClick={() => handleActivateDistribution(distribution)}
                                    />
                                }
                            </span>
                        </div>
                    )}
                </Loadable>
            </div>
        </div>
    );
};

export default SegmentPlatforms;
