import {ApiModel} from "../ApiModel";
import {EmailInfo} from "./EmailInfo";
import {ContactTopic} from "./ContactTopic";

export enum ContactMessageField {
    EMAIL_INFO = "email_info",
    TOPIC = "topic",
    MESSAGE = "message",
    TAGS = "tags"
}

export class ContactMessage extends ApiModel {
    email_info: EmailInfo = new EmailInfo();
    topic: string = ContactTopic.GENERAL_QUESTION;
    message: string = "";
    tags: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            email_info: this.email_info,
            topic: this.topic,
            message: this.message.replaceAll("\n", "<br/>"),
            tags: this.tags
        };
    }
}
