import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationCommonFile} from "../../utils/constants";
import {session} from "../../../api/ApiSession";
import {CONTACT_TOPICS, ContactTopic} from "../../api/model/account/ContactTopic";
import {Action, Alert, AlertItem, AlertSeverity, Button, ButtonSize, FieldBlock, FormLayoutButtons, FormLayoutColumns, FormLayoutRows, InputEmail, ModalContent, ModalNew, ModalTitle, Paragraph, Select, Textarea, TranslationLibFile} from "@sirdata/ui-lib";
import {UIEventManager} from "../../utils/UIEventManager";
import {LoggedAccount} from "../../api/interface/LoggedAccount";
import {ContactMessage, ContactMessageField} from "../../api/model/account/ContactMessage";
import {Origin} from "../../api/model/Origin";
import {PORTAL} from "../../../utils/Portal";

type ModalContactFormArgs = {
    topicValue: string;
    tags: string[];
    message: string;
    onClose?: () => void;
}

const ModalContact: React.FC = () => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContact} = useTranslation(TranslationCommonFile.CONTACT);
    const [isActive, setActive] = useState<boolean>(false);
    const [onClose, setOnClose] = useState<() => void>(() => 0);
    const [alertMessage, setAlertMessage] = useState<AlertItem>();
    const [account, setAccount] = useState<LoggedAccount>();
    const [availableContactMessages, setAvailableContactMessages] = useState<ContactMessage[]>([]);
    const [currentContactMessage, setCurrentContactMessage] = useState<ContactMessage>(new ContactMessage());

    const handleChange = (field: ContactMessageField, value: any) => {
        setCurrentContactMessage((prevState) => new ContactMessage({...prevState, [field]: value}));
    };

    const handleClose = () => {
        setAvailableContactMessages([]);
        setCurrentContactMessage(new ContactMessage());
        setActive(false);
        setAlertMessage(undefined);
        onClose && onClose();
    };

    const handleSubmit = async () => {
        setAlertMessage(undefined);
        try {
            const contactMessage = new ContactMessage({
                [ContactMessageField.TOPIC]: textContact(`topic.${currentContactMessage.topic}`),
                [ContactMessageField.MESSAGE]: currentContactMessage.message,
                [ContactMessageField.TAGS]: currentContactMessage.tags
            });
            await session.restPortal.sendContactMessage(contactMessage);

            setAlertMessage({text: textContact("message.mail_sent"), severity: AlertSeverity.SUCCESS});
            setTimeout(() => handleClose(), 2000);
        } catch (e) {
            setAlertMessage({text: textContact("message.mail_error"), severity: AlertSeverity.DANGER});
            setTimeout(() => setAlertMessage(undefined), 5000);
        }
    };

    useEffect(() => {
        UIEventManager.addListener(ModalContactUiEvent, (args: ModalContactFormArgs) => {
            const newAvailableContactMessages: ContactMessage[] = CONTACT_TOPICS.map((it) => new ContactMessage({
                [ContactMessageField.TOPIC]: it,
                [ContactMessageField.MESSAGE]: textContact(`placeholder.${it}`),
                [ContactMessageField.TAGS]: args.tags
            }));

            const selectedTopic = args?.topicValue || ContactTopic.GENERAL_QUESTION;
            const existingContactMessage = newAvailableContactMessages.find((it) => it.topic === selectedTopic);
            if (existingContactMessage) {
                setCurrentContactMessage(existingContactMessage);
            } else {
                const newContactMessage = new ContactMessage({
                    [ContactMessageField.TOPIC]: args.topicValue,
                    [ContactMessageField.MESSAGE]: args.message,
                    [ContactMessageField.TAGS]: args.tags
                });
                newAvailableContactMessages.push(newContactMessage);
                setCurrentContactMessage(newContactMessage);
            }
            setAvailableContactMessages(newAvailableContactMessages);

            (async () => {
                try {
                    setAccount(await session.getAccount());
                } catch (e) {
                }
            })();

            setActive(true);
            setOnClose(() => args.onClose);
        });

        return () => {
            UIEventManager.removeAllListeners(ModalContactUiEvent);
        };
    }, [availableContactMessages, textContact]);

    const hasEmptyField = () => {
        return !account?.email || !currentContactMessage.topic || !currentContactMessage.message;
    };

    return (
        <ModalNew onClose={handleClose} active={isActive}>
            <ModalContent>
                <ModalTitle>{textContact("title")}</ModalTitle>
                <FormLayoutRows>
                    {Origin.CMP.name === PORTAL.origin.name &&
                        <Paragraph>
                            <span dangerouslySetInnerHTML={{__html: textContact("documentation_url.cmp")}}/>
                        </Paragraph>
                    }
                    {Origin.GTM_SERVER_SIDE.name === PORTAL.origin.name &&
                        <Paragraph>
                            <span dangerouslySetInnerHTML={{__html: textContact("documentation_url.gtm_server_side")}}/>
                        </Paragraph>
                    }
                    <FormLayoutColumns>
                        <FieldBlock label={textContact("field.sender")}>
                            <InputEmail
                                value={account?.email || ""}
                                onChange={() => 0}
                                disabled
                            />
                        </FieldBlock>
                        <FieldBlock label={textContact(`field.${ContactMessageField.TOPIC}`)}>
                            <Select
                                value={currentContactMessage.topic}
                                options={availableContactMessages.map((it) => ({
                                    value: it.topic,
                                    label: textContact(`topic.${it.topic}`),
                                    item: it
                                }))}
                                onChange={(option) => setCurrentContactMessage(option?.item)}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FieldBlock label={textContact(`field.${ContactMessageField.MESSAGE}`)}>
                        <Textarea
                            placeholder={textContact(`placeholder.${ContactMessageField.MESSAGE}`)}
                            value={currentContactMessage.message}
                            onChange={(value) => handleChange(ContactMessageField.MESSAGE, value)}
                            rows={10}
                            autoFocus
                            required
                        />
                    </FieldBlock>
                    <FormLayoutButtons>
                        <Button size={ButtonSize.BIG} disabled={hasEmptyField()} onClick={handleSubmit}>
                            {textCommon(Action.SEND.labelKey)}
                        </Button>
                    </FormLayoutButtons>
                    {alertMessage &&
                        <Alert text={alertMessage.text} severity={alertMessage.severity}/>
                    }
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalContact;
export const ModalContactUiEvent = "ModalContact";
