import React, {FunctionComponent, useEffect, useState} from "react";
import {session} from "../../api/ApiSession";
import {FolderFavorites} from "../../api/model/FolderFavorites";
import {TranslationPortalFile} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {onKeyEnter} from "../../common/utils/form";
import {Action, Icon, IconTooltip} from "@sirdata/ui-lib";
import {TaxonomyElement} from "../../api/model/taxonomy/TaxonomyElement";
import {handleCatchError} from "../../common/utils/helper";

type FavoriteButtonProps = {
    element: TaxonomyElement;
};

const FavoriteButton: FunctionComponent<FavoriteButtonProps> = ({element}) => {
    const {t: textFavorites} = useTranslation(TranslationPortalFile.FAVORITES);
    const [folders, setFolders] = useState<FolderFavorites[]>([]);
    const [isShowFavorites, setShowFavorites] = useState<boolean>();
    const [newFolderName, setNewFolderName] = useState<string>("");

    useEffect(() => {
        (async () => {
            setFolders(await session.getFavoriteFolders());
        })();
    }, [isShowFavorites]);

    const toggleFavorite = async (folderId: number) => {
        try {
            await session.toggleFavoriteElement(folderId, element);
            setFolders(await session.loadFavoriteFolders());
        } catch (e) {
            handleCatchError(e, (e) => console.error("Failed to update favorites:", e.message));
        }
    };

    const doCreateFolder = async () => {
        try {
            let newFolder = new FolderFavorites();
            newFolder.name = newFolderName;
            newFolder.elements = [element];
            await session.restFavorite.createFolder(newFolder);
            setFolders(await session.loadFavoriteFolders());
            setNewFolderName("");
        } catch (e) {
            handleCatchError(e, (e) => console.error("Failed to create folder:", e.message));
        }
    };

    const isFavorite = () => {
        return folders.find((folder) => folder.hasElement(element));
    };

    return (
        <div
            className={clsx("favorite-button", {"favorite-button--active": isShowFavorites})}
            onClick={(e) => e.stopPropagation()}
            onMouseLeave={() => setShowFavorites(false)}
        >
            <IconTooltip
                icon={{name: "favorite", outlined: !isFavorite()}}
                text={textFavorites(isFavorite() ? "actions.remove_from_favorites" : "actions.add_to_favorites")}
                cssClass="favorite-button__icon"
                onClick={() => setShowFavorites((prev) => !prev)}
            />
            <div className="favorite-button__overlay" onClick={() => setShowFavorites(false)}/>
            <div className="favorite-button__content">
                <div className="favorite-create">
                    <input
                        type="text"
                        value={newFolderName}
                        placeholder={textFavorites("form.create_folder")}
                        onFocus={(e) => e.stopPropagation()}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        onKeyDown={(e) => onKeyEnter(e, doCreateFolder)}
                        autoComplete="off"
                    />
                    <Icon {...Action.ADD.icon} cssClass="favorite-create__icon"/>
                </div>
                {folders.map((folder) =>
                    <div key={folder.id} className="favorite-item" onClick={() => toggleFavorite(folder.id)}>
                        <Icon name="favorite" outlined={!folder.hasElement(element)}/>
                        <span className="favorite-item__name">{folder.name}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FavoriteButton;
